body {
    margin: 0;
    font-family: 'IBM Plex Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    width: 100vw;
    background-color: #0B0F15;
}

code {
    font-family: 'IBM Plex Sans', sans-serif;
}

.blur {
    filter: blur(50px);
}